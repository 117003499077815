// App.js
import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

function ChatModal() {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "http://localhost:8081/submit-feedback",
        { feedback }
      );
      // Handle success
      console.log("Feedback submitted:", response.data);
      // Reset feedback input or handle submission success
      setFeedback(""); // Resetting the feedback after submission
    } catch (error) {
      // Handle error
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <Box
      sx={{
        width: 300,
        maxWidth: "100%",
        mx: "auto", // Margin X (left & right) auto for centering
      }}
    >
      <TextField
        fullWidth
        label="Your Feedback"
        multiline
        rows={4}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit Feedback
      </Button>
    </Box>
  );
}

export default ChatModal;
