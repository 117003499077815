// import React from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Link,
//   Typography,
//   IconButton,
// } from "@mui/material";
// import WwfLogo from "../../images/mapmax-copyright.png";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import PhoneIcon from "@mui/icons-material/Phone";
// import MailIcon from "@mui/icons-material/Mail";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// function Footer() {
//   return (
//     <Box sx={{ backgroundColor: "grey.100", color: "grey.800", pt: 4, pb: 2 }}>
//       <Container maxWidth="xl">
//         <Grid container spacing={3} justifyContent="center" alignItems="start">
//           {/* Logo */}
//           <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center", mb: 2 }}>
//             <img
//               src={WwfLogo}
//               alt="Mapmax Logo"
//               style={{ maxWidth: "250px", height: "auto", margin: "auto" }}
//             />
//           </Grid>
//           {/* Address and Serving Area */}
//           <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center", mb: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               Currently serving
//             </Typography>
//             <Typography>
//               Manhattan, Brooklyn, Queens, The Bronx, Staten Island, NYC;
//             </Typography>
//             <Typography>
//               Nassau and Suffolk, Long Island, NY; and all counties of New
//               Jersey
//             </Typography>
//           </Grid>
//           {/* Connect with us and IT Related Queries */}
//           <Grid item xs={12} md={6} sx={{ textAlign: "center", mb: 2 }}>
//             <Grid container>
//               <Grid item xs={12} md={6}>
//                 <Typography
//                   variant="subtitle1"
//                   gutterBottom
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   Connect
//                 </Typography>
//                 <Link
//                   href="mailto:mapmax360@gmail.com"
//                   color="inherit"
//                   sx={{ display: "flex", justifyContent: "center", mb: 1 }}
//                 >
//                   <MailIcon sx={{ mr: 1 }} />
//                   mapmax360@gmail.com
//                 </Link>
//                 <Link
//                   href="tel:+16466565806"
//                   color="inherit"
//                   sx={{ display: "flex", justifyContent: "center", mb: 1 }}
//                 >
//                   <PhoneIcon sx={{ mr: 1 }} />
//                   +1 (646) 656-5806
//                 </Link>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Typography
//                   variant="subtitle1"
//                   gutterBottom
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   IT Queries
//                 </Typography>
//                 <Link
//                   href="mailto:ithelp.mapmax@gmail.com"
//                   color="inherit"
//                   sx={{ display: "flex", justifyContent: "center", mb: 1 }}
//                 >
//                   <MailIcon sx={{ mr: 1 }} />
//                   ithelp.mapmax@gmail.com
//                 </Link>
//                 <Link
//                   href="tel:+16466566559"
//                   color="inherit"
//                   sx={{ display: "flex", justifyContent: "center" }}
//                 >
//                   <PhoneIcon sx={{ mr: 1 }} />
//                   +1 (646) 656-6559
//                 </Link>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         {/* Legal Links */}
//         <Grid
//           container
//           spacing={3}
//           justifyContent="space-evenly"
//           sx={{ mt: 3, textAlign: "center" }}
//         >
//           <Grid item xs={4} sm={2}>
//             <Link href="/disclaimer" color="inherit">
//               Disclaimer
//             </Link>
//           </Grid>
//           <Grid item xs={4} sm={2}>
//             <Link href="/termsofuse" color="inherit">
//               Terms of use
//             </Link>
//           </Grid>
//           <Grid item xs={4} sm={2}>
//             <Link href="/userprivacy" color="inherit">
//               User Privacy
//             </Link>
//           </Grid>
//         </Grid>
//         {/* Copyright */}
//         <Typography
//           variant="body2"
//           color="text.secondary"
//           align="center"
//           sx={{ mt: 4 }}
//         >
//           © 2024 Mapmax LLC. Patent Pending. All rights reserved.
//         </Typography>
//       </Container>
//     </Box>
//   );
// }

// export default Footer;







import React from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import WwfLogo from "../../images/mapmax-copyright.png";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";

function Footer() {
  return (
    <Box sx={{ backgroundColor: "grey.100", color: "grey.800", pt: 4, pb: 2 }}>
      <Container maxWidth="xl">
        <Grid container spacing={3} justifyContent="center" alignItems="start">
          {/* Logo */}
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center", mb: 2 }}>
            <img
              src={WwfLogo}
              alt="Mapmax Logo"
              style={{ maxWidth: "250px", height: "auto", margin: "auto" }}
            />
          </Grid>
          {/* Address and Serving Area */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            <Typography            variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}>
              Currently serving
            </Typography>
            <Typography>
              Manhattan, Brooklyn, Queens, The Bronx, Staten Island, NYC;
            </Typography>
            <Typography>
              Nassau and Suffolk, Long Island, NY; and all counties of New
              Jersey
            </Typography>
          </Grid>
          {/* Connect and IT Related Queries */}
          <Grid item xs={12} md={3} sx={{ textAlign: "center", mb: 2 }}>
            <Grid container>
              {/* Connect Section */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Connect
                </Typography>
                <Link
                  href="mailto:mapmax360@gmail.com"
                  color="inherit"
                  sx={{ display: "flex", justifyContent: "center", mb: 1 }}
                >
                  <MailIcon sx={{ mr: 1 }} />
                  mapmax360@gmail.com
                </Link>
                <Link
                  href="tel:+16466565806"
                  color="inherit"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <PhoneIcon sx={{ mr: 1 }} />
                  +1 (646) 656-5806
                </Link>
              </Grid>
              {/* IT Queries Section */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  IT Queries
                </Typography>
                <Link
                  href="mailto:ithelp.mapmax@gmail.com"
                  color="inherit"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <MailIcon sx={{ mr: 1 }} />
                  ithelp.mapmax@gmail.com
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Legal Links */}
        <Grid
          container
          spacing={3}
          justifyContent="space-evenly"
          sx={{ mt: 3, textAlign: "center" }}
        >
          <Grid item xs={4} sm={2}>
            <Link href="/disclaimer" color="inherit">
              Disclaimer
            </Link>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Link href="/termsofuse" color="inherit">
              Terms of use
            </Link>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Link href="/userprivacy" color="inherit">
              User Privacy
            </Link>
          </Grid>
        </Grid>
        {/* Copyright */}
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 4 }}
        >
          © 2024 Mapmax LLC. Patent Pending. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
