import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../Contexts/AuthContext"; // Adjust the import path as needed
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Paper,
  Stack,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Background = styled("div")(({ theme }) => ({
  backgroundImage: "url(loginBackground.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 500,
}));

const StyledForm = styled("form")(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Login = () => {
  const [authMode, setAuthMode] = useState("signin");
  const [error, setError] = useState(""); // For managing error messages
  const [success, setSuccess] = useState(""); // For managing success messages
  const history = useHistory();
  const { login } = useAuth(); // Ensure login is provided correctly by your context

  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
  });

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setAuthMode(newMode);
      setError(""); // Clear error on auth mode change
    }
  };

  const handleInputChange = (event) => {
    setError("");
    setSuccess("");
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleGoogleLogin = () => {
    const redirectTo = encodeURIComponent(
      authMode === "signup" ? "/addlisting" : "/"
    );
    window.location.href = `http://localhost:8081/auth/google?redirectTo=${redirectTo}`;
  };

  const handleSubmit = async (event) => {
    console.log("Attempting to submit form");
    event.preventDefault();

    const apiBaseURL = "http://localhost:8081"; // Update this according to your server
    const endpoint = authMode === "signup" ? "/auth/register" : "/auth/login";

    try {
      const response = await axios.post(
        `${apiBaseURL}${endpoint}`,
        userDetails
      );
      console.log(response.data); // Log the response

      if (response.data && response.data.token) {
        login(response.data.user, response.data.token);
        setSuccess(
          `Successfully ${authMode === "signup" ? "registered" : "logged in"}!`
        );
        history.push("/");
      } else {
        setError("Unexpected response from the server");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setError(error.response?.data.message || "An unexpected error occurred");
    }
  };

  // const handleLogout = () => {
  //   logout();
  //   history.push("/"); // Redirect to home or login page
  // };
  return (
    <Background>
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={6}>
          <Stack spacing={2} width="100%">
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <Typography variant="h4" align="center">
              {authMode === "signup" ? "Sign Up" : "Sign In"}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={authMode}
              exclusive
              onChange={handleModeChange}
              fullWidth
            >
              <ToggleButton value="signin">Sign In</ToggleButton>
              <ToggleButton value="signup">Sign Up</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <StyledForm onSubmit={handleSubmit}>
            {authMode === "signup" && (
              <TextField
                label="Username"
                name="username"
                required
                value={userDetails.username}
                onChange={handleInputChange}
              />
            )}
            <TextField
              label="Email Address"
              name="email"
              type="email"
              required
              value={userDetails.email}
              onChange={handleInputChange}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              required
              value={userDetails.password}
              onChange={handleInputChange}
            />
            {authMode === "signup" && (
              <TextField
                label="Phone Number"
                name="phone"
                required
                value={userDetails.phone}
                onChange={handleInputChange}
              />
            )}
            <Button type="submit" variant="contained" sx={{ mt: 1 }}>
              {authMode === "signup" ? "Sign Up" : "Sign In"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 1 }}
              onClick={handleGoogleLogin}
            >
              {authMode === "signup"
                ? "Sign Up with Google"
                : "Sign In with Google"}
            </Button>
            {authMode === "signin" && (
              <Button
                color="secondary"
                sx={{ mt: 2 }}
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password?
              </Button>
            )}
          </StyledForm>
        </StyledPaper>
      </Container>
    </Background>
  );
};

export default Login;
