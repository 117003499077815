import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NavigateTextPage from "./NavigateText.js";
import CommercialReviewPage from "./CommercialReviewPage.js";
import ResidentialReviewPage from "./ResidentialReviewPage.js";
import UseMapPage from "./MapPage.js";

// Custom theme settings
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    background: {
      default: "#f4f5f7",
    },
  },
  typography: {
    h2: {
      fontWeight: 500,
      fontSize: "2.2rem",
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
    header: {
      fontWeight: 600,
      fontSize: "2.5rem",
      margin: "20px 0",
      color: "#333",
    },
    cardHeader: {
      fontSize: "1.7rem", // Increased font size
      fontWeight: 400,
      marginBottom: "10px",
      color: "#4682b4", // New color
      display: "flex",
      justifyContent: "center",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
  },
});

function HowToUsePage() {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box py={3} textAlign="center">
          <Typography variant="header" gutterBottom>
            Learn How to Use
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Feature Cards */}
          {/* Card 1 */}
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/usemappage")}
                >
                  Map Page
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="MapPage.png"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/usemappage")}
                />

                <Typography variant="body2" color="text.secondary" pt={3}>
                  Discover locations using our dynamic map interface, advanced
                  visualization tools, and our unique "RETONE" algorithm. For
                  detailed instructions on how to navigate and utilize the map
                  page, click on "Learn More".
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/usemappage")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF",
                    "&:hover": {
                      backgroundColor: "#33ab9f",
                      color: "#99CCFF",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Card 2 */}
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/commercialreviewpage")}
                >
                  Commercial Review Page
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="CommercialPage.png"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/commercialreviewpage")}
                />

                <Typography variant="body2" color="text.secondary" pt={3}>
                  Harness data-driven insights with our advanced commercial real
                  estate analysis tools. Use our RETONE interactive map for
                  dynamic mapping and visualization. Evaluate property
                  fundamentals with detailed data analysis and an assessment
                  checklist. Consider demographics, topography, and nearby
                  businesses to find the ideal location. For more information,
                  visit our Commercial page.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/commercialreviewpage")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF",
                    "&:hover": {
                      backgroundColor: "#33ab9f",
                      color: "#99CCFF",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/residentialreviewpage")}
                >
                  Residential Review Page
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="ResidentialPage.png"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/residentialreviewpage")}
                />

                <Typography variant="body2" color="text.secondary" pt={3}>
                  Choosing a home is all about location. Use our RETONE
                  interactive map to explore your new neighborhood easily. Find
                  your perfect space with detailed property data and analysis.
                  Consider nearby businesses and environmental factors to make
                  your house a perfect home. For more information, visit our
                  Residential page.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/residentialreviewpage")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF",
                    "&:hover": {
                      backgroundColor: "#33ab9f",
                      color: "#99CCFF",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Card 4 */}
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/navigatetextpage")}
                >
                  Text Instructions
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="steps.jpg"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/navigatetextpage")}
                />

                <Typography variant="body2" color="text.secondary" pt={3}>
                  Navigate through this section with ease by following our
                  step-by-step instructions. For detailed guidance, click on
                  "Learn More" below.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/navigatetextpage")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF",
                    "&:hover": {
                      backgroundColor: "#33ab9f",
                      color: "#99CCFF",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {/* Summary Text */}
        <Box py={4} textAlign="left"></Box>
      </Container>
    </ThemeProvider>
  );
}

export default HowToUsePage;
