import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as selectors from "../../../Reducers/selectors";
import ContactInfo from "./ContactInfo";
import LocationDetails from "./LocationDetails";
import PricingInfo from "./PricingInfo";
import SelectLocation from "./SelectLocation";
import Submit from "./submit";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getListingById,
  downloadListingImage,
} from "../../../Requests/listings-requests";
import SaveModal from "./SaveModal";
// import { useAuth0 } from "@auth0/auth0-react";
// import { withAuth0 } from "@auth0/auth0-react";
// // import withAuthentication from "./Authentication";

class AddListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      body: "",
      index: 0,
      formData: { contactInfo: {}, locationDetails: {}, pricingInfo: {} },
      photos: { cover_photos: [], contact_photos: [], site_photos: [] },
      contactInfoIncomplete: true,
      locationDetailsIncomplete: true,
      pricingInfoIncomplete: true,
      updateInfo: {},
      isUpdate: false,
      saveKey: -1,
      photo_update_key: -1, // needed for [][] to descend to children
      showSaveModal: false,
      loading: false,
      message: "",
      wrongUser: false,
      selectLocationComplete: false,
      locationDetailsComplete: false,
      pricingInfoComplete: false,
      contactInfoComplete: false,
      submitComplete: false,
    };
  }

  componentDidMount() {
    this.handleUpdateParams();
  }

  handleUpdateParams = async () => {
    const { urlParams, user } = this.props;
    if (!urlParams.listingId) return;

    let res = await getListingById(urlParams.listingId);

    // check if owned by user
    if (user._id !== res[0]?.user_id) {
      this.setState({ wrongUser: true });
    }

    this.setState({ index: 1 });

    let photoRes = res[0].photos;
    let photos = { contact_photos: [], cover_photos: [], site_photos: [] };
    if (photoRes) {
      photos = {
        contact_photos: await Promise.all(
          photoRes.contact_photos.map((e) => this.downloadImage(e))
        ),
        cover_photos: await Promise.all(
          photoRes.cover_photos.map((e) => this.downloadImage(e))
        ),
        site_photos: await Promise.all(
          photoRes.site_photos.map((e) => this.downloadImage(e))
        ), //res[0].photos.site_photos.map(async e => await this.downloadImage(e))
      };
    }

    this.setState({
      formData: res[0],
      location: res[0].location,
      photos: photos,
      // contactInfoIncomplete: false,
      // locationDetailsIncomplete: false,
      // pricingInfoIncomplete: false,
      // isUpdate: true,
    });
  };

  downloadImage = async (uri) => {
    let response = await downloadListingImage(uri);
    let fileName = uri.substring(uri.lastIndexOf("/") + 1, uri.length);
    let file = new File([response], fileName);
    return file;
  };

  setLocation = (location) => {
    this.setState({ location: location });
    this.addFormInfo(location, "location");
    this.handleNext("selectLocationComplete");
  };

  handleChange = (e) => {
    this.setState({ body: e.target.value });
  };

  handleTabPress = (index) => {
    this.setState({ index: index });
  };

  addFormInfo = async (data, category) => {
    await this.setState({
      formData: { ...this.state.formData, [category]: data },
    });
    this.setState({
      contactInfoIncomplete:
        this.state.formData.contactInfo.forSale === undefined ||
        this.state.formData.contactInfo.forLease === undefined ||
        this.state.formData.contactInfo.relationship === undefined,
    });
  };

  handleNext = async (e) => {
    this.setState({
      index: this.state.index + 1,
      [e]: true,
    });
    window.scrollTo(0, 0);
  };

  handleBack = async (index) => {
    this.setState({
      index,
    });
  };

  handleSetPhotos = (category, photos) => {
    let photosState = { ...this.state.photos, [category]: photos };

    this.setState({
      photos: photosState,
      photo_update_key: -1 * this.state.photo_update_key,
      locationDetailsIncomplete: photosState.cover_photos.length === 0,
    });
  };

  removePhoto = (category, index) => {
    let photosState = this.state.photos;
    photosState[category].splice(index, 1);

    this.setState({
      photos: photosState,
      photo_update_key: this.state.photo_update_key * -1,
      locationDetailsIncomplete: photosState.cover_photos.length === 0,
    });
  };

  handleOpenSave = () => {
    this.setState({ showSaveModal: true });
  };

  handleSave = () => {
    this.setState({ saveKey: -1 * this.state.saveKey, loading: true });
  };

  handleCancelSave = () => {
    this.setState({ showSaveModal: false });
  };

  setSaveData = (loading, message) => {
    this.setState({ loading: loading, message: message });
  };
  setFormRef = (form) => {
    this.setState({ contactInfoForm: form });
  };

  render() {
    const {
      isUpdate,
      location,
      index,
      photos,
      formData,
      photo_update_key,
      saveKey,
      wrongUser,
      showSaveModal,
      loading,
      message,
      // selectLocationComplete,
      // locationDetailsComplete,
      // pricingInfoComplete,
      // contactInfoComplete,
      // submitComplete,
    } = this.state;

    if (wrongUser) {
      return (
        <div className="warningPage">
          <h3>Sorry, you do not own this listing.</h3>
        </div>
      );
    }
    return (
      <div className="addListingPage">
        <div className="bannerContainer">
          <div className="bannerHeader">
            <div className="bannerImage">
              <h3 className="bannerText">New Listing</h3>
            </div>
          </div>
        </div>
        <div className="toolbarContainer">
          <Button
            className={`tabItem ${index === 0 && "SelectedtabItem"}`}
            onClick={() => this.handleTabPress(0)}
            variant="none"
          >
            <span>Location</span>
          </Button>
          <Button
            className={`tabItem ${index === 1 && "SelectedtabItem"}`}
            onClick={() => this.handleTabPress(1)}
            variant="none"
          >
            <span>Location Details</span>
          </Button>
          <Button
            className={`tabItem ${index === 2 && "SelectedtabItem"}`}
            onClick={() => this.handleTabPress(2)}
            variant="none"
          >
            <span>Pricing {"&"} terms</span>
          </Button>
          <Button
            className={`tabItem ${index === 3 && "SelectedtabItem"}`}
            onClick={() => this.handleTabPress(3)}
            variant="none"
          >
            <span>Contact Info</span>
          </Button>
          <Button
            className={`tabItem ${index === 4 && "SelectedtabItem"}`}
            onClick={() => this.handleTabPress(4)}
            variant="none"
          >
            <span>Submit</span>
          </Button>
        </div>
        <div className="formPageContainer">
          <div style={index === 0 ? {} : { display: "none" }}>
            <SelectLocation setLocation={this.setLocation} />
          </div>

          <div style={index === 1 ? {} : { display: "none" }}>
            <LocationDetails
              addFormInfo={this.addFormInfo}
              handleNext={() => this.handleNext("locationDetailsComplete")}
              handleSetPhotos={this.handleSetPhotos}
              photos={photos}
              photo_update_key={photo_update_key}
              handleRemovePhoto={this.removePhoto}
              updateInfo={formData.locationDetails}
              isUpdate={isUpdate}
              handleBack={this.handleBack}
            />
          </div>
          <div style={index === 2 ? {} : { display: "none" }}>
            <PricingInfo
              addFormInfo={this.addFormInfo}
              handleNext={() => this.handleNext("pricingInfoComplete")}
              photoKey={photo_update_key}
              updateInfo={formData.pricingInfo}
              isUpdate={isUpdate}
              handleBack={this.handleBack}
            />
          </div>
          <div style={index === 3 ? {} : { display: "none" }}>
            <ContactInfo
              addFormInfo={this.addFormInfo}
              handleNext={() => this.handleNext("contactInfoComplete")}
              handleSetPhotos={this.handleSetPhotos}
              photos={photos.contact_photos}
              handleRemovePhoto={this.removePhoto}
              photoKey={photo_update_key}
              updateInfo={formData.contactInfo}
              isUpdate={isUpdate}
              handleBack={this.handleBack}
              setFormRef={this.setFormRef}
            />
          </div>
          <div style={index === 4 ? {} : { display: "none" }}>
            <Submit
              handleNext={() => this.handleNext("submitComplete")}
              formData={formData}
              photos={photos}
              photoKey={photo_update_key}
              isUpdate={isUpdate}
              saveKey={saveKey}
              setSaveData={this.setSaveData}
              handleBack={this.handleBack}
              contactInfoForm={this.state.contactInfoForm}
            />
            <div className="saveButton">
              {index !== 0 && (
                <SaveButton handleOpenSave={this.handleOpenSave} />
              )}
            </div>

            <SaveModal
              show={showSaveModal}
              onHide={this.handleCancelSave}
              handleSave={this.handleSave}
              loading={loading}
              message={message}
            />
          </div>

          <div>
            {index !== 0 && (
              <span
                className="bottomSaveText"
                style={{
                  fontSize: "25px",
                  color: "blue",
                  alignItems: "center",
                }}
              >
                To save draft in progess Click "Save" on Submit page
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectors.addressSelector,
  selectors.userSelector,
  (address, user) => ({
    address,
    user,
  })
);

const withAuthentication = (Component) => {
  return function WrappedComponent(props) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    return (
      <Component
        {...props}
        isAuthenticated={isAuthenticated}
        loginWithRedirect={loginWithRedirect}
      />
    );
  };
};

const SaveButton = (props) => {
  const { handleOpenSave } = props;
  return (
    <div>
      <Button
        className="nextButton"
        variant="primary"
        style={{ backgroundColor: "#007bff", fontWeight: "bold" }}
        onClick={handleOpenSave}
      >
        Save as a draft
      </Button>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(AddListing));
