import React from "react";
import BoroughData from "./NycData";

const Data311 = () => {
  return (
    <div>
      <BoroughData />
    </div>
  );
};

export default Data311;
